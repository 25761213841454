import {
  Box,
  Card,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useCart } from "../organisms/cart";

const minYear = new Date().getFullYear() - 18 - 100 + 1;

export const InfoCard = () => {
  const { order, info, setInfo, processing, validation } = useCart()!;
  return order ? null : (
    <Card elevation={0} sx={{ mt: 1 }}>
      <Box sx={{ padding: 1 }}>
        <Typography
          sx={{
            pb: 1,
            borderColor: "background.default",
            borderStyle: "solid",
            borderWidth: "0 0 1px",
          }}
        >
          Information
        </Typography>
      </Box>
      <Box sx={{ padding: 1 }}>
        <FormGroup>
          <FormLabel component="legend">Date de naissance</FormLabel>
          <Box sx={{ display: "flex" }}>
            <FormControl
              sx={{
                flexGrow: 0,
                flexShrink: 0,
                flexBasis: "25%",
              }}
              variant="outlined"
              size="small"
              fullWidth
            >
              <Select
                sx={{
                  color:
                    info.birthDate === "" ? "rgba(0, 0, 0, 0.25)" : "inherit",
                }}
                native
                value={info.birthDate}
                onChange={(e) =>
                  setInfo({ ...info, birthDate: e.target.value })
                }
                disabled={processing}
                error={!!validation.birthError}
              >
                <option value="">Jour</option>
                {new Array(31).fill(0).map((_, v) => (
                  <option key={v} value={v + 1}>
                    {v + 1}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                ml: 1,
                flexGrow: 0,
                flexShrink: 0,
                flexBasis: "25%",
              }}
              variant="outlined"
              size="small"
              fullWidth
            >
              <Select
                sx={{
                  color:
                    info.birthMonth === "" ? "rgba(0, 0, 0, 0.25)" : "inherit",
                }}
                native
                value={info.birthMonth}
                onChange={(e) =>
                  setInfo({ ...info, birthMonth: e.target.value })
                }
                disabled={processing}
                error={!!validation.birthError}
              >
                <option value="">Mois</option>
                {new Array(12).fill(0).map((_, v) => (
                  <option key={v} value={v + 1}>
                    {Intl.DateTimeFormat("fr", { month: "long" }).format(
                      Date.UTC(1970, v)
                    )}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{ ml: 1 }}
              variant="outlined"
              size="small"
              fullWidth
            >
              <Select
                sx={{
                  color:
                    info.birthYear === "" ? "rgba(0, 0, 0, 0.25)" : "inherit",
                }}
                native
                value={info.birthYear}
                onChange={(e) =>
                  setInfo({ ...info, birthYear: e.target.value })
                }
                disabled={processing}
                error={!!validation.birthError}
              >
                <option value="">Année</option>
                {new Array(100).fill(0).map((_, v) => (
                  <option key={v} value={v + minYear}>
                    {v + minYear}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
          <FormHelperText
            error={!!validation.birthError}
            children={validation.birthError || ""}
          />
          <Typography sx={{ mt: 1, mb: 2 }} variant="body2">
            Vous devez avoir l'âge légal autorisé pour acheter ou consommer de
            l'alcool.
          </Typography>
        </FormGroup>
        <TextField
          label="Prénom Nom"
          size="small"
          fullWidth
          sx={{ mb: 1 }}
          value={info.name}
          onChange={(e) => setInfo({ ...info, name: e.target.value })}
          disabled={processing}
          required
          error={!!validation.nameError}
          helperText={validation.nameError || ""}
        />
        <TextField
          name="email"
          label="E-mail"
          size="small"
          fullWidth
          sx={{ mb: 1 }}
          value={info.email}
          onChange={(e) => setInfo({ ...info, email: e.target.value })}
          disabled={processing}
          required
          error={!!validation.mailError}
          helperText={validation.mailError || ""}
        />
        <TextField
          label="Téléphone"
          size="small"
          fullWidth
          sx={{ mb: 1 }}
          value={info.phone}
          onChange={(e) => setInfo({ ...info, phone: e.target.value })}
          disabled={processing}
          required
          error={!!validation.phoneError}
          helperText={validation.phoneError || ""}
        />
      </Box>
      <Box sx={{ px: 1, pb: 2 }}>
        <Typography variant="body2">
          Vos données personnelles ne seront pas utilisées ou communiquées à des
          fins commerciales, elles serviront uniquement dans le cadre du suivi
          et de la livraison de votre commande.
        </Typography>
      </Box>
    </Card>
  );
};
