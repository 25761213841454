import React, { FC } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ProductCard } from "../molecules/product-card";
import { NavigateBefore } from "@mui/icons-material";
import { ConfirmationCard } from "../molecules/confirmation-card";
import { Cart } from "./cart";
import { ShippingCard } from "../molecules/shipping-card";
import { InfoCard } from "../molecules/info-card";
import { PaymentCard } from "../molecules/payment-card";
import { Container } from "../layouts/container";
import { GatsbyShop } from "../utils/shop";

export const Products: FC<{
  shop: GatsbyShop;
  root: string;
  order?: boolean;
  preview?: boolean;
  onBack: () => void;
  onCheckout: () => void;
}> = ({ shop, root, order = false, preview = false, onBack, onCheckout }) => {
  return (
    <Container>
      <Grid spacing={1} container justifyContent="center">
        {!order && (
          <Grid xs={12} sm={12} md={8} xl={9} item container spacing={1}>
            {shop.products.filter(({ show }) => show).length === 0 ? (
              <Grid item>
                <Typography
                  sx={{
                    color: "text.secondary",
                    padding: "1rem",
                  }}
                >
                  Aucun produit.
                </Typography>
              </Grid>
            ) : (
              <>
                {shop.products
                  .filter(({ show }) => show)
                  .map((product) => (
                    <Grid item key={product.sku} xs={12} sm={6} xl={4}>
                      <ProductCard {...product} domain={shop.domain} />
                    </Grid>
                  ))}
              </>
            )}
          </Grid>
        )}
        <Grid
          xs={12}
          sm={order ? 10 : 12}
          md={order ? 8 : 4}
          xl={order ? 6 : 3}
          item
        >
          <Box
            sx={{
              position: "sticky",
              top: "4.625rem",
            }}
          >
            {order && (
              <Button
                color="primary"
                onClick={onBack}
                startIcon={<NavigateBefore />}
                sx={{ mb: 1, textTransform: "capitalize" }}
              >
                Retourner à la boutique
              </Button>
            )}
            <ConfirmationCard root={root} />
            <Cart
              shop={shop}
              minimum={shop.shipping.minimum}
              packing={shop.shipping.packing}
              updatable={!order}
              onCheckout={onCheckout}
            />
            {order && (
              <>
                <ShippingCard shop={shop} />
                <InfoCard />
                {!preview && <PaymentCard shop={shop} />}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
