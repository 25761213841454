import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCart } from "../organisms/cart";
import { formatPrice } from "../../api/shared/utils";
import { Opening } from "../../api/shared/shop";
import { findFairs } from "../../api/shared/cart";
import { RebateBox } from "./rebate-box";
import { DateCalendar } from "@mui/x-date-pickers";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/fr";
import { GatsbyShop } from "../utils/shop";

dayjs.locale("fr");

const dateDisabled = (
  current: Dayjs,
  opening: Opening = {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
    sunday: false,
    holidays: false,
    hours: "",
  }
) => {
  if (current.day() === 0 && !opening.sunday) return true;
  if (current.day() === 1 && !opening.monday) return true;
  if (current.day() === 2 && !opening.tuesday) return true;
  if (current.day() === 3 && !opening.wednesday) return true;
  if (current.day() === 4 && !opening.thursday) return true;
  if (current.day() === 5 && !opening.friday) return true;
  if (current.day() === 6 && !opening.saturday) return true;
  if (opening.holidays) return false;
  const a = current.year() % 19;
  const b = Math.floor(current.year() / 100);
  const c = current.year() % 100;
  const d = Math.floor(b / 4);
  const e = b % 4;
  const f = Math.floor((b + 8) / 25);
  const g = Math.floor((b - f + 1) / 3);
  const h = (19 * a + b - d - g + 15) % 30;
  const i = Math.floor(c / 4);
  const k = c % 4;
  const l = (32 + 2 * e + 2 * i - h - k) % 7;
  const m = Math.floor((a + 11 * h + 22 * l) / 451);
  const n = Math.floor((h + l - 7 * m + 114) / 31);
  const p = (h + l - 7 * m + 114) % 31;
  const easter = current.month(n - 1).date(p + 1);

  const holidays = [
    easter.add(1, "day"), // Lundi de Pâques
    easter.add(39, "day"), // Jeudi de l'Ascension
    easter.add(50, "day"), // Lundi de Pentecôte
    easter.month(0).date(1), // Jour de l'an
    easter.month(4).date(1), // Fête du Travail
    easter.month(4).date(8), // Fête de la Victoire
    easter.month(6).date(14), // Fête nationale
    easter.month(7).date(15), // Assomption
    easter.month(10).date(1), // Toussaint
    easter.month(10).date(11), // Armistice
    easter.month(11).date(25), // Noël
  ];
  return !!holidays.find((date) => date.isSame(current));
};

export const ShippingCard = ({ shop }: { shop: GatsbyShop }) => {
  const {
    order,
    shipping,
    setShipping,
    price,
    regions,
    processing,
    validation,
  } = useCart()!;

  const fairs = findFairs(shop);
  const [selectedFair, setSelectedFair] = useState(0);

  useEffect(() => {
    let date = dayjs();
    while (dateDisabled(date, shop.shipping.clickAndCollect.opening)) {
      date = date.add(1, "day");
    }
    const day = date.format("YYYYMMDD");
    if (shipping.mode === "fair") {
      const fair = fairs[selectedFair];
      setShipping({
        ...shipping,
        fair: fair?.name ?? "",
        address: fair?.address ?? "",
        zipcode: fair?.zipcode ?? "",
        city: fair?.city ?? "",
        day: undefined,
      });
    } else if (shipping.mode === "estate") {
      setShipping({
        ...shipping,
        fair: undefined,
        address: "",
        zipcode: "",
        city: "",
        day,
      });
    } else {
      setShipping({
        ...shipping,
        fair: undefined,
        address: "",
        zipcode: "",
        city: "",
        day: undefined,
      });
    }
  }, [shipping.mode, selectedFair]);

  return order ? null : (
    <Card elevation={0} sx={{ mt: 1, px: 1 }}>
      <Box sx={{ pt: 1 }}>
        <Typography
          sx={{
            pb: 1,
            borderColor: "background.default",
            borderStyle: "solid",
            borderWidth: "0 0 1px",
          }}
        >
          Livraison
        </Typography>
      </Box>
      <Box sx={{ py: 1 }}>
        <RadioGroup
          aria-label="mode"
          value={shipping.mode}
          onChange={(e) =>
            setShipping({
              ...shipping,
              mode: e.target.value as any,
            })
          }
          row
        >
          <FormControlLabel
            value="home"
            control={<Radio />}
            label="À domicile"
          />
          {shop.shipping.clickAndCollect.enabled && (
            <FormControlLabel
              value="estate"
              control={<Radio />}
              label="À la propriété"
            />
          )}
          {fairs.length > 0 && (
            <FormControlLabel
              value="fair"
              control={<Radio />}
              label="Sur événement"
            />
          )}
        </RadioGroup>
      </Box>
      {shipping.mode === "home" && (
        <>
          <Box sx={{ py: 1 }}>
            <TextField
              label="Adresse"
              size="small"
              fullWidth
              sx={{ mb: 1 }}
              value={shipping.address}
              onChange={(e) =>
                setShipping({ ...shipping, address: e.target.value })
              }
              disabled={processing}
              required
              error={!!validation.addressError}
              helperText={validation.addressError || ""}
            />
            <Box sx={{ mb: 1, display: "flex" }}>
              <TextField
                label="Code Postal"
                size="small"
                value={shipping.zipcode}
                onChange={(e) =>
                  setShipping({ ...shipping, zipcode: e.target.value })
                }
                disabled={processing}
                required
                error={!!validation.zipcodeError}
                helperText={validation.zipcodeError || ""}
              />
              <TextField
                label="Ville"
                size="small"
                fullWidth
                sx={{ ml: 1 }}
                value={shipping.city}
                onChange={(e) =>
                  setShipping({ ...shipping, city: e.target.value })
                }
                disabled={processing}
                required
                error={!!validation.cityError}
                helperText={validation.cityError || ""}
              />
            </Box>
            <FormGroup>
              <FormControl variant="outlined" size="small" fullWidth>
                <Select
                  fullWidth
                  native
                  value={shipping.region}
                  onChange={(e) =>
                    setShipping({ ...shipping, region: e.target.value })
                  }
                  disabled={processing}
                  error={!!validation.regionError}
                >
                  {regions.map((region) => (
                    <option key={region} value={region}>
                      {region}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {validation.regionError && (
                <FormHelperText
                  error={!!validation.regionError}
                  children={validation.regionError}
                />
              )}
            </FormGroup>
          </Box>
        </>
      )}
      {shipping.mode === "estate" && (
        <Grid container sx={{ py: 1 }} spacing={1}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "rgba(0,0,0,0.23)",
                borderRadius: "0.25rem",
                height: "100%",
                px: "1rem",
                py: "0.5rem",
              }}
            >
              <Typography sx={{ mb: 0.5, fontWeight: "bold" }}>
                {shop.name}
              </Typography>
              <Typography variant="body2" sx={{ mb: 0.25 }}>
                {shop.shipping.clickAndCollect.address}
              </Typography>
              <Typography variant="body2" sx={{ mb: 0.5 }}>
                {shop.shipping.clickAndCollect.zipcode}{" "}
                {shop.shipping.clickAndCollect.city}
              </Typography>
              <Link
                href={`https://www.google.com/maps/place/${encodeURIComponent(
                  `${shop.shipping.clickAndCollect.address} ${shop.shipping.clickAndCollect.zipcode} ${shop.shipping.clickAndCollect.city}`
                )}`}
                target="_blank"
              >
                <Typography variant="body2">Voir sur la carte</Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                flex: 1,
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "rgba(0,0,0,0.23)",
                borderRadius: "0.25rem",
                height: "100%",
                px: "1rem",
                py: "0.5rem",
              }}
            >
              <Typography sx={{ fontWeight: "bold", display: "inline-block" }}>
                Ouverture&nbsp;
              </Typography>
              <Typography
                variant="body2"
                sx={{ mb: 0.25, fontStyle: "italic", display: "inline-block" }}
              >
                (
                {`${
                  shop.shipping.clickAndCollect.opening?.holidays
                    ? "ouvert"
                    : "fermé"
                } les jours fériés`}
                )
              </Typography>
              <Typography variant="body2" sx={{ mb: 0.5 }}>
                {[
                  shop.shipping.clickAndCollect.opening?.monday
                    ? "lundi"
                    : null,
                  shop.shipping.clickAndCollect.opening?.tuesday
                    ? "mardi"
                    : null,
                  shop.shipping.clickAndCollect.opening?.wednesday
                    ? "mercredi"
                    : null,
                  shop.shipping.clickAndCollect.opening?.thursday
                    ? "jeudi"
                    : null,
                  shop.shipping.clickAndCollect.opening?.friday
                    ? "vendredi"
                    : null,
                  shop.shipping.clickAndCollect.opening?.saturday
                    ? "samedi"
                    : null,
                  shop.shipping.clickAndCollect.opening?.sunday
                    ? "dimanche"
                    : null,
                ]
                  .filter((a) => !!a)
                  .join(", ")}
              </Typography>
              <Box sx={{ mb: 0.5 }}>
                <Typography sx={{ mb: 0.25, fontWeight: "bold" }}>
                  Horaires
                </Typography>
                <Typography variant="body2">
                  {shop.shipping.clickAndCollect.opening?.hours}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Accordion
              variant="outlined"
              TransitionProps={{ unmountOnExit: true }}
              disableGutters
              sx={{
                bgcolor: "transparent",
                borderRadius: "0.25rem",
                borderColor: "rgba(0,0,0,0.23)",
                width: "100%",
                ":before": { display: "none" },
              }}
            >
              <AccordionSummary
                sx={{ minHeight: "38px", height: "38px", px: "14px" }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography
                  sx={{
                    position: "absolute",
                    color: "primary.main",
                    transformOrigin: "top left",
                    transform: "translate(10px, -9px) scale(0.75)",
                    bgcolor: "background.paper",
                    px: "5px",
                    left: 0,
                    top: 0,
                  }}
                >
                  Date de retrait envisagée *
                </Typography>
                <Typography>
                  {dayjs(shipping.day, "YYYYMMDD").format("dddd D MMMM")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  borderColor: "rgba(0,0,0,0.10)",
                  borderTopWidth: "1px",
                  borderTopStyle: "solid",
                  py: 0,
                }}
              >
                <DateCalendar
                  disablePast
                  views={["day"]}
                  value={dayjs(shipping.day, "YYYYMMDD")}
                  onChange={(date: Dayjs | null) =>
                    setShipping({ ...shipping, day: date?.format("YYYYMMDD") })
                  }
                  shouldDisableDate={(date: Dayjs) =>
                    dateDisabled(date, shop.shipping.clickAndCollect.opening)
                  }
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      )}
      {shipping.mode === "fair" && (
        <RadioGroup
          value={selectedFair}
          onChange={(event) => setSelectedFair(parseInt(event.target.value))}
        >
          {fairs.map((fair, idx) => (
            <Box
              key={idx}
              sx={{
                py: 1,
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Radio value={idx} />
              <Box
                sx={{
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "rgba(0,0,0,0.23)",
                  borderRadius: "0.25rem",
                  flexGrow: 1,
                  px: "1rem",
                  py: "0.5rem",
                }}
              >
                <Typography sx={{ mb: 0.5, fontWeight: "bold" }}>
                  {fair.name}{" "}
                  <Typography component="span" sx={{ color: "text.secondary" }}>
                    (
                    {new Intl.DateTimeFormat("fr", {
                      day: "numeric",
                      month: "short",
                    }).format(Date.parse(fair.start))}
                    {" - "}
                    {new Intl.DateTimeFormat("fr", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    }).format(Date.parse(fair.end))}
                    )
                  </Typography>
                </Typography>
                <Typography sx={{ mb: 0.25 }}>{fair.address}</Typography>
                <Typography sx={{ mb: 0.5 }}>
                  {fair.zipcode} {fair.city}
                </Typography>
                <Link
                  href={`https://www.google.com/maps/place/${encodeURIComponent(
                    `${fair.address} ${fair.zipcode} ${fair.city}`
                  )}`}
                  target="_blank"
                >
                  <Typography sx={{}}>Voir sur la carte</Typography>
                </Link>
              </Box>
            </Box>
          ))}
        </RadioGroup>
      )}
      <TextField
        label="Commentaire"
        size="small"
        fullWidth
        value={shipping.comment}
        onChange={(e) => setShipping({ ...shipping, comment: e.target.value })}
        disabled={processing}
        multiline
        rows={2}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          py: 1,
          borderColor: "background.default",
          borderStyle: "solid",
          borderWidth: "0 0 1px",
        }}
      >
        <Typography flexGrow={1} color="text.secondary" variant="body2">
          Livraison
          {price.surcharge > 0 && (
            <Typography component="span" variant="body2">
              {" "}
              (dont {formatPrice(price.surcharge)} de majoration)
            </Typography>
          )}
        </Typography>
        <Typography
          flexGrow={1}
          color="text.secondary"
          variant="body2"
          fontWeight="bold"
          textAlign="right"
        >
          {formatPrice(price.shipping)}
        </Typography>
      </Box>
      <RebateBox {...price.shippingRebate.unconditional} />
      <RebateBox {...price.shippingRebate.amount} />
      <RebateBox {...price.shippingRebate.quantity} />
      <RebateBox {...price.shippingRebate.code} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          py: 1,
        }}
      >
        <Typography flexGrow={1} color="text.secondary" variant="body2">
          Sous-total livraison
        </Typography>
        <Typography
          flexGrow={1}
          color="text.secondary"
          variant="body2"
          fontWeight="bold"
          textAlign="right"
        >
          {formatPrice(price.shippingSubtotal)}
        </Typography>
      </Box>
    </Card>
  );
};
