import {
  Box,
  Card,
  CardActions,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Button } from "../atoms/button";
import { CheckCircleOutline } from "@mui/icons-material";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useCart } from "../organisms/cart";
import axios from "axios";
import { Order } from "../../api/shared/shop";
import { formatPrice } from "../../api/shared/utils";
import { validateCart } from "../../api/shared/cart";
import { GatsbyShop } from "../utils/shop";

const StripeInput = React.forwardRef<HTMLElement>((props, ref) => {
  return (
    <CardElement
      options={{
        style: { base: { fontSize: "16px" } },
        hidePostalCode: true,
        disabled: Boolean((props as any).disabled),
      }}
      {...props}
      onReady={(element) => (ref as any)(element)}
    />
  );
});

export const PaymentCard = ({ shop }: { shop: GatsbyShop }) => {
  const {
    items,
    info,
    shipping,
    processing,
    setProcessing,
    price: { shipping: delivery, total },
    code,
    order,
    setOrder,
    clear,
    validation,
    setPristine,
  } = useCart()!;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [complete, setComplete] = useState<boolean>(false);
  const [consent, setConsent] = useState<boolean>(false);
  const elements = useElements();
  const stripe = useStripe();

  const proceed = async () => {
    setPristine(false);
    if (validateCart(shipping, info, shop).valid) {
      setProcessing(true);
      const cardElement = elements?.getElement(CardElement);
      if (stripe && cardElement) {
        const order: Order = {
          shop: shop.id,
          ...info,
          ...shipping,
          code,
          items,
        };
        const {
          data: { client_secret, oid },
        } = await axios.post("/api/orders", order);
        const { error } = await stripe.confirmCardPayment(client_secret, {
          payment_method: {
            card: cardElement,
          },
        });
        setErrorMessage(error?.message || null);
        if (!error) {
          try {
            await axios.post("/api/orders", { oid, ...order });
            await clear();
            setOrder(oid);
          } catch {
            // TODO handle error
          }
          try {
            const dataLayer: any[] = (window as any).dataLayer;
            if (dataLayer) {
              dataLayer.push({ ecommerce: null });
              dataLayer.push({
                event: "purchase",
                ecommerce: {
                  transaction_id: oid,
                  affiliation: shop.name,
                  value: total / 100,
                  shipping: delivery / 100,
                  currency: "EUR",
                  coupon: order.code,
                  items: items.map(
                    ({
                      product: { sku, name, vintage, color, price },
                      quantity,
                    }) => ({
                      item_id: sku,
                      item_name: name,
                      item_category: color,
                      item_variant: vintage,
                      quantity: quantity,
                      price: price / 100,
                      currency: "EUR",
                    })
                  ),
                },
              });
            }
          } catch {
            // Do nothing
          }
        }
        setProcessing(false);
      }
    }
  };

  return order ? null : (
    <Card elevation={0} sx={{ mt: 1 }}>
      <Box sx={{ padding: 1 }}>
        <Typography
          sx={{
            pb: 1,
            borderColor: "background.default",
            borderStyle: "solid",
            borderWidth: "0 0 1px",
          }}
        >
          Paiement
        </Typography>
      </Box>
      <Box sx={{ px: 1, pt: 1 }}>
        <TextField
          fullWidth
          label="Numéro de carte"
          sx={{
            ".StripeElement": {
              paddingTop: "11px !important",
              paddingBottom: "6px !important",
            },
          }}
          error={!!errorMessage}
          helperText={errorMessage ? errorMessage || "Invalid" : ""}
          onChange={(e) => {
            const { error, complete } =
              e as any as StripeCardElementChangeEvent;
            setComplete(complete);
            setErrorMessage(error?.message || null);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ disabled: processing }}
          InputProps={{
            inputComponent: StripeInput as any,
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={consent}
              onChange={(e) => setConsent(e.target.checked)}
              color="primary"
              disabled={processing}
            />
          }
          label={
            <Typography variant="body2">
              J'ai lu et j'accepte les conditions générales de vente
            </Typography>
          }
        />
      </Box>
      <CardActions>
        <Button
          onClick={proceed}
          disabled={!validation.valid || !complete || !consent || processing}
          processing={processing}
        >
          <Typography
            component="span"
            sx={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <CheckCircleOutline fontSize="small" sx={{ mr: 0.5 }} />
            <Typography component="span">Payer {formatPrice(total)}</Typography>
          </Typography>
        </Button>
      </CardActions>
    </Card>
  );
};
