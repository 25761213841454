import { Box, Card, CardActions, Typography } from "@mui/material";
import React from "react";
import { useCart } from "../organisms/cart";
import { CheckCircleOutline } from "@mui/icons-material";
import { Button } from "../atoms/button";

export const ConfirmationCard = ({ root }: { root: string }) => {
  const { order } = useCart()!;
  return order ? (
    <Card elevation={0} sx={{ textAlign: "center" }}>
      <Box sx={{ padding: 2, height: "6rem" }}>
        <CheckCircleOutline sx={{ fontSize: "4rem" }} color="primary" />
      </Box>
      <Box sx={{}}>
        <Typography sx={{ fontWeight: "bold" }}>
          Commande{" "}
          <Typography
            sx={{ fontWeight: "bold", color: "text.secondary" }}
            component="span"
          >
            {order}
          </Typography>{" "}
          validée !
        </Typography>
        <Typography>Vous allez recevoir un mail de confirmation.</Typography>
      </Box>
      <CardActions sx={{ justifyContent: "center" }}>
        <Box sx={{ padding: 1 }}>
          <Button href={`${root}/`}>
            <Typography component="span">Retour à la boutique</Typography>
          </Button>
        </Box>
      </CardActions>
    </Card>
  ) : null;
};
